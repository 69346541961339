// src/components/CourseSelector/CourseSelector.js
import { Box, FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useGetThreadsByUser } from "../../hooks/useGetThreadsByUser";
import { filterByCourse } from "../../utils/filterByCourse";

export const CourseSelector = ({
  isTyping,
  loadingCreateThread,
  setMessages,
  coursesData,
  selectedCourse,
  handleCourseChange,
  handleCourseStats,
  isAdmin,
  getStudentsInCourse,
  selectedStudent,
  setSelectedStudent,
  handleStudentChange,
  setFilteredThreads,
}) => {
  const hasCourses = coursesData.length > 0;
  const [students, setStudents] = useState([]); // State to store students
  const [open, setOpen] = React.useState(null);
  const [quizModalOpen, setQuizModalOpen] = useState(false);
  const { fetchThreadsByUser } = useGetThreadsByUser();

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };

  const handleStudentChangeClick = async (event) => {
    const studentId = event.target.value;
    if (studentId === "courseStats") {
      handleCourseStats();
      setFilteredThreads([]);
    } else {
      setSelectedStudent(studentId);
      try {
        const fetchThreadsByUserResponseData = await fetchThreadsByUser(
          studentId
        );
        const studentThreads = fetchThreadsByUserResponseData.data;
        const newFilteredThreads = filterByCourse(
          selectedCourse,
          studentThreads
        );
        setFilteredThreads(newFilteredThreads);
      } catch (error) {
        console.error("Error fetching threads by user:", error);
      }
    }
    setMessages(null);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const visuallyHiddenStyle = {
    position: "absolute",
    left: "-10000px",
    top: "auto",
    width: "1px",
    height: "1px",
    overflow: "hidden",
  };

  useEffect(() => {
    const fetchStudents = async () => {
      if (selectedCourse && isAdmin) {
        const studentsData = await getStudentsInCourse(selectedCourse);

        setStudents(studentsData);
        setSelectedStudent("courseStats"); // Reset selected student when course changes
      }
    };

    fetchStudents();
  }, [selectedCourse, isAdmin]);

  return (
    <Box sx={{ padding: 2 }}>
      {hasCourses && (
        <FormControl fullWidth>
          <InputLabel
            id="course-select-label"
            className="Mui-visuallyHidden"
            sx={visuallyHiddenStyle}
          >
            Select Course
          </InputLabel>
          <Select
            labelId="course-select-label"
            value={selectedCourse}
            disabled={isTyping || loadingCreateThread}
            onChange={handleCourseChange}
            fullWidth
            sx={{ height: "64px", marginBottom: 2 }}
            variant="outlined"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            {coursesData.map((course, index) => (
              <MenuItem key={index} value={course}>
                {course}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
      {isAdmin && (
        <FormControl fullWidth>
          <InputLabel
            id="student-select-label"
            className="Mui-visuallyHidden"
            sx={visuallyHiddenStyle}
          >
            Select Student
          </InputLabel>
          <Select
            labelId="student-select-label"
            value={selectedStudent}
            onChange={handleStudentChangeClick}
            fullWidth
            sx={{ height: "64px", marginBottom: 2 }}
            variant="outlined"
            MenuProps={{
              PaperProps: {
                style: {
                  maxHeight: "300px",
                },
              },
            }}
          >
            <MenuItem value="courseStats">Course Statistics</MenuItem>
            {students.map((student, index) => (
              <MenuItem key={index} value={student}>
                {student}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};
