import { Box, Typography } from "@mui/material";
import React from "react";
import { useMobile } from "../../contexts/MobileContext";

export const NewThreadEncourager = () => {
  const isMobile = useMobile().isMobile;
  return (
    <Box
      sx={{
        position: "absolute",
        bottom: isMobile ? "25%" : "18%",
        right: "2%",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: 3,
        borderRadius: 2,
        boxShadow: `0 4px 8px rgba(0, 0, 0, 0.1)`,
        backgroundColor: "white",
        zIndex: 1000,
        opacity: 0.9,
        maxWidth: "20%",
      }}
    >
      <Typography variant="body1" color="textPrimary" mt={1} mb={2}>
        Long conversations can reduce clarity. Starting a new thread helps keep
        the conversation on track. Click the "+" to do so.
      </Typography>
    </Box>
  );
};
