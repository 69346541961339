import { AddCircleOutline as AddCircleOutlineIcon } from "@mui/icons-material";
import { Box, CircularProgress, List, Typography } from "@mui/material";
import React, { useCallback, useEffect, useState } from "react";
import { useGetThreadsByUser } from "../../hooks/useGetThreadsByUser";
import { filterByCourse } from "../../utils/filterByCourse";
import { ThreadListItem } from "./ThreadListItem";

export const ThreadList = ({
  createdNewThread,
  loadingCreateThread,
  isTyping,
  selectedCourse,
  currentThread,
  onThreadClick,
  handleMenuOpen,
  theme,
  isMobile,
  handleRenameThread,
  handleDeleteThread,
  user,
  isAdmin,
  userThreads,
}) => {
  const [filteredThreads, setFilteredThreads] = useState([]);
  const [selectedThread, setSelectedThread] = useState(null);
  const {
    mutate: getThreads,
    isLoading,
    isError,
    error,
    data,
  } = useGetThreadsByUser();

  const refetchThreads = useCallback(() => {
    if (!isAdmin) {
      getThreads(user.userId);
    }
  }, [getThreads, user, isAdmin]);

  useEffect(() => {
    refetchThreads();
  }, [refetchThreads, createdNewThread]);

  useEffect(() => {
    if (data) {
      const newFilteredThreads = filterByCourse(selectedCourse, data);
      setFilteredThreads(newFilteredThreads);
    }
  }, [user, data, selectedCourse]);

  const handleDeleteThreadMemo = (threadToDelete) => {
    setFilteredThreads((prevThreads) =>
      prevThreads.filter(
        (thread) => thread.threadId !== threadToDelete.threadId
      )
    );
    handleDeleteThread(threadToDelete);
  };

  const handleRenameThreadMemo = (threadToRename, newName) => {
    setFilteredThreads((prevThreads) =>
      prevThreads.map((thread) =>
        thread.threadId === threadToRename.threadId
          ? { ...thread, threadName: newName }
          : thread
      )
    );
    handleRenameThread(threadToRename, newName);
  };

  // Error handling
  if (isError) {
    return (
      <Typography color="error">
        Error fetching threads: {error.message}
      </Typography>
    );
  }

  // Loading state
  if (isLoading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100%"
      >
        <CircularProgress />
      </Box>
    );
  }

  // Empty state handling
  if (filteredThreads.length === 0) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="70%"
        textAlign="center"
        padding={1}
        marginTop={-15}
      >
        {isMobile && (
          <>
            <AddCircleOutlineIcon fontSize="large" color="action" />
            <Typography variant="h6" color="textSecondary" marginTop={2}>
              No threads available
            </Typography>
            <Typography variant="body1" color="textSecondary" marginTop={1}>
              Send your first message.
            </Typography>
          </>
        )}

        {!isMobile && (
          <>
            <AddCircleOutlineIcon fontSize="large" color="action" />
            <Typography variant="h6" color="textSecondary" marginTop={2}>
              No threads available for this course
            </Typography>
            <Typography variant="body1" color="textSecondary" marginTop={1}>
              Start a new conversation by sending your first message.
            </Typography>
          </>
        )}
      </Box>
    );
  }

  return (
    <List sx={{ overflowY: "auto", flexGrow: 1, position: "relative" }}>
      {filteredThreads.map((thread, index) => (
        <ThreadListItem
          isTyping={isTyping}
          loadingCreateThread={loadingCreateThread}
          key={thread.threadId}
          thread={thread}
          index={index}
          currentThread={currentThread}
          onThreadClick={onThreadClick}
          handleMenuOpen={handleMenuOpen}
          theme={theme}
          isMobile={isMobile}
          handleRenameThread={handleRenameThreadMemo}
          handleDeleteThread={handleDeleteThreadMemo}
          setSelectedThread={setSelectedThread}
        />
      ))}
    </List>
  );
};
