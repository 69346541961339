import { Snackbar } from "@mui/base";
import { Alert, CircularProgress } from "@mui/material";
import { Analytics } from "@vercel/analytics/react";
import React, { useEffect, useRef, useState } from "react";
import { QueryClient, QueryClientProvider } from "react-query";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import "./App.css";
import ChatWindow from "./components/ChatWindow";
import CookieConsent from "./components/CookieConsent";
import { CourseStatistics } from "./components/CourseStatistics/CourseStatistics";
import LoginPage from "./components/LoginPage";
import NavBar from "./components/NavBar";
import Onboarding from "./components/Onboarding/Onboarding";
import SideBar from "./components/SideBar";
import ChatWindowSkeleton from "./components/Skeletons/ChatWindowSkeleton";
import { MobileProvider, useMobile } from "./contexts/MobileContext";
import { useCreateThread } from "./hooks/useCreateThread";
import { useDeleteThread } from "./hooks/useDeleteThread";
import { useGetThreadMessages } from "./hooks/useGetThreadMessages";
import { useGetThreadsByUser } from "./hooks/useGetThreadsByUser";
import { useGetUser } from "./hooks/useGetUser";
import { useGetUsersByCourse } from "./hooks/useGetUsersByCourse";
import { useGoogleSignIn } from "./hooks/useGoogleSignIn";
import { useHandleEnrollInCourse } from "./hooks/useHandleEnrollInCourse";
import { useOpenAssistantResponseWs } from "./hooks/useOpenAssistantResponseWs";
import { useRenameThread } from "./hooks/useRenameThread";
import { useStreamAssistantResponse } from "./hooks/useStreamAssistantResponse";
import { ThemeProvider, useThemeContext } from "./theme/ThemeContext";
const queryClient = new QueryClient();

const App = () => {
  return (
    <Router>
      <MobileProvider>
        <QueryClientProvider client={queryClient}>
          <ThemeProvider>
            <Routes>
              <Route path="/" element={<InnerApp />} />
              <Route path="/onboarding" element={<Onboarding />} />
            </Routes>
            <Analytics />
          </ThemeProvider>
        </QueryClientProvider>
      </MobileProvider>
    </Router>
  );
};
export const LONG_RESPONSE_MESSAGE =
  "I need some time to think about this, please be patient...";

const InnerApp = () => {
  const getLocalUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  const [user, setUser] = useState(getLocalUser());
  const [isLoading, setIsLoading] = useState(true);
  const [inAdmin, setInAdmin] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState("courseStats"); // State for selected student
  const isMobile = useMobile().isMobile;

  const [currentThread, setCurrentThread] = useState({});
  const currentThreadRef = useRef(currentThread);
  const [messages, setMessages] = useState([]);
  const [currentMessage, setCurrentMessage] = useState("");
  const [lastMessageSender, setLastMessageSender] = useState(null);
  const {
    mutate: getThreads,
    isLoading: isLoadingThreads,
    data: userThreadData,
  } = useGetThreadsByUser();
  const { mutate: getMessages, isLoading: isLoadingThreadMessages } =
    useGetThreadMessages(setMessages);

  const handleSignupSuccess = async (user) => {
    setAuthenticating(true);
    setUser(user);
    handleLoginSuccess(user);
    setAuthenticating(false);
  };

  const setToGhostThread = () => {
    setCurrentThread({});
    currentThreadRef["current"] = null;
    setMessages([]);
  };

  const { googleSignIn } = useGoogleSignIn();
  const handleCredentialResponse = async (credentialResponse) => {
    setAuthenticating(true);
    try {
      const loginResponseData = await googleSignIn(credentialResponse);
      const loginResponse = loginResponseData.data;
      if (loginResponse.statusCode !== 200) {
        handleLoginFailure(AUTH_FAILURE_MESSAGE);
      } else {
        // to prevent users from logging in, uncomment the line below
        // handleLoginFailure(TAILORED_TUTOR_DOWN_MESSAGE);
        setUser(loginResponse.response);
        handleLoginSuccess(loginResponse.response);
      }
    } catch (error) {
      console.error("There was an error signing in:", error);
      handleLoginFailure(AUTH_FAILURE_MESSAGE);
    }
    setAuthenticating(false);
    return {};
  };

  const getThreadMessages = async (thread) => {
    const payload = {
      method: "getThreadMessages",
      threadId: thread.threadId,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        const data = await response.json();
        setMessages(data);
      } else {
        // Handle non-200 responses
      }
    } catch (error) {
      console.error(
        "There was an error fetching the threadMessages data:",
        error
      );
    }
    return {};
  };

  const assistantResponseWebsocket = useRef(null);
  const isFirstMessageRef = useRef(true);
  const { openAssistantResponseWs } = useOpenAssistantResponseWs();
  const { streamAssistantResponse } = useStreamAssistantResponse();
  const getAIResponse = async (userMessage) => {
    // open the websocket if it's not already open
    setIsTyping(true);
    try {
      if (
        !assistantResponseWebsocket.current ||
        assistantResponseWebsocket.current.readyState !== WebSocket.OPEN
      ) {
        const ws = await openAssistantResponseWs();
        if (!ws) {
          console.error(
            "Could not open a new websocket connection because of an error."
          );
          return;
        }
        assistantResponseWebsocket.current = ws;
        // Set up an onmessage event handler to print incoming messages to the console
        assistantResponseWebsocket.current.onmessage = (event) => {
          const streamMessage = JSON.parse(event.data);

          // Update the placeholder message with the AI response
          setMessages((prevMessages) => {
            const updatedMessages = [...prevMessages];
            const currentContent =
              updatedMessages[placeholderIndexRef.current].content[
                updatedMessages[placeholderIndexRef.current].content.length - 1
              ].text;
            if (isFirstMessageRef.current) {
              updatedMessages[placeholderIndexRef.current] = {
                ...updatedMessages[placeholderIndexRef.current],
                content: [{ text: streamMessage }],
                isTyping: false,
              };
              isFirstMessageRef.current = false;
            } else {
              updatedMessages[placeholderIndexRef.current] = {
                ...updatedMessages[placeholderIndexRef.current],
                content: [{ text: currentContent + streamMessage }],
              };
            }
            return updatedMessages;
          });
        };
      }
      if (!assistantResponseWebsocket.current) {
        console.error("Websocket connection is not open.");
        return;
      }
    } catch (error) {
      console.error(
        "Could not open a new websocket connection because of ",
        error
      );
      return;
    }

    try {
      const response = await streamAssistantResponse(
        currentThreadRef.current.threadId,
        userMessage,
        assistantResponseWebsocket.current
      );
      return "testing in progress, remember to remove this line and stream the response from the assistant to the message";
    } catch (error) {
      console.error(
        "There was an error fetching the assistant response:",
        error
      );
      return "Sorry, there was an error in fetching the assistant response. Please refresh the page and try again.";
    }
  };

  const [userThreads, setUserThreads] = useState([]);

  const { getUsersByCourse } = useGetUsersByCourse();
  const fetchStudentsForCourse = async (courseId) => {
    try {
      const getUsersByCourseResponseData = await getUsersByCourse(courseId);
      const getUsersByCourseResponse = getUsersByCourseResponseData.data;
      if (getUsersByCourseResponse.statusCode !== 200) {
        console.error(
          "Error fetching students:",
          getUsersByCourseResponse.response
        );
        return [];
      }
      return getUsersByCourseResponse.response;
    } catch (error) {
      console.error("Error fetching students:", error);
    }
  };

  const placeholderIndexRef = useRef(null);
  const sendMessage = async () => {
    if (currentMessage !== "") {
      const userMessage = currentMessage;
      // Append user's message
      setLastMessageSender("user");
      setMessages((prevMessages) => [
        ...prevMessages,
        { content: [{ text: userMessage }], role: "user" },
      ]);
      setCurrentMessage("");

      // Create a placeholder message for the assistant response
      const placeholderMessage = {
        content: [{ text: "Let me think this one through..." }],
        role: "assistant",
        isTyping: true,
      };
      setLastMessageSender("assistant");
      setMessages((prevMessages) => {
        placeholderIndexRef.current = prevMessages.length; // Store the index of the placeholder message
        return [...prevMessages, placeholderMessage];
      });

      // Get AI response
      isFirstMessageRef.current = true;
      const aiResponse = await getAIResponse(userMessage);
      setIsTyping(false);
    }
  };

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [isLongAwait, setIsLongAwait] = React.useState(false);
  const [isTyping, setIsTyping] = React.useState(false);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  useEffect(() => {
    currentThreadRef.current = currentThread;
  }, [currentThread]);

  const handleThreadClick = async (thread, isNew) => {
    if (currentThread === thread) {
      return;
    }
    await setCurrentThread(thread);
    setMessages([]);
    if (!isNew) {
      await getMessages(thread.threadId);
    }
  };

  const [drawerOpen, setDrawerOpen] = useState(true);

  const drawerWidth = isMobile ? 150 : 240; // Adjust the width as needed

  const { theme, toggleTheme } = useThemeContext(); // Use the theme from the context

  const mainStyle = (open) => ({
    transition: theme.transitions.create("margin", {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    marginLeft: 0,
    ...(open && {
      transition: theme.transitions.create("margin", {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
      marginLeft: drawerWidth,
    }),
  });

  const [coursesData, setCoursesData] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState("");
  const [courseThreads, setCourseThreads] = useState([]);

  /**
   * On loading the page, check if the user is logged in (from this session or a previous one).
   * Once the user is logged in, fetch any data needed for rendering from the backend.
   */
  const { getUser } = useGetUser();
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!user) {
        const localUser = JSON.parse(localStorage.getItem("user"));
        if (localUser) {
          return;
        } else {
          return;
        }
      }
      try {
        const getUserResponseData = await getUser(user.userId);
        const getUserResponse = getUserResponseData.data;
        if (getUserResponse.statusCode !== 200) {
          handleLoginFailure(LOGGED_OUT_MESSAGE);
        } else {
          const userData = getUserResponse.response;
          setCoursesData(userData.enrolledCourses);
          const newCourse = userData.enrolledCourses[0] || "";
          setSelectedCourse(newCourse);
          await fetchThreadsForCourse(newCourse, userData.userId);
        }
      } catch (error) {
        console.error("There was an error fetching the user data: ", error);
        handleLoginFailure(LOGGED_OUT_MESSAGE);
      }
      setIsLoading(false);
    };

    fetchData();
  }, [user]);

  useEffect(() => {
    const fetchData = async () => {
      if (!inAdmin) {
        if (user) {
          await fetchThreadsForCourse(selectedCourse, user.userId);
          setCurrentThread({});
          setMessages([]);
        }
      }
    };
    fetchData();
  }, [selectedCourse, user]);

  useEffect(() => {
    const filteredThreads = filterCourseThreads(selectedCourse);
    setCourseThreads(filteredThreads);
  }, [userThreads]);

  const filterCourseThreads = (courseId) => {
    return userThreads
      .filter((thread) => thread.courseId === courseId)
      .sort((a, b) => {
        // Check if both threads have the 'lastMessageSentTimeISO8601' attribute
        if (a.lastMessageSentTimeISO8601 && b.lastMessageSentTimeISO8601) {
          // Both threads have the attribute, sort by the ISO8601 time (latest first)
          return (
            new Date(b.lastMessageSentTimeISO8601) -
            new Date(a.lastMessageSentTimeISO8601)
          );
        } else if (a.lastMessageSentTimeISO8601) {
          // Only 'a' has the attribute, so 'a' should come before 'b'
          return -1;
        } else if (b.lastMessageSentTimeISO8601) {
          // Only 'b' has the attribute, so 'b' should come before 'a'
          return 1;
        } else {
          // Neither thread has the attribute, maintain existing order
          return 0;
        }
      });
  };

  const fetchThreadsForCourse = async (courseId, userId) => {
    //await fetchThreadsForUser(userId);
    const filteredThreads = filterCourseThreads(courseId);
    setCourseThreads(filteredThreads);
    return filteredThreads;
  };

  const handleCourseChange = async (event) => {
    const newCourse = event.target.value;
    setSelectedCourse(newCourse);
    if (inAdmin) {
      setCourseThreads([]);
    }
    setMessages([]);
    setCurrentThread({});
  };

  const handleStudentChange = (event) => {
    const studentId = event.target.value;
    if (studentId === "courseStats") {
      handleCourseStats();
    } else {
      setSelectedStudent(studentId);
      const threads = fetchThreadsForCourse(selectedCourse, studentId);
      setUserThreads(threads);
    }
    setMessages([]);
    setCurrentThread({});
  };

  const capitalizeWords = (str) => {
    return str.replace(/\b(\w)/g, (s) => s.toUpperCase());
  };

  const handleStartQuizClick = async (topic, questionCount, answerType) => {
    const payload = {
      method: "createQuiz",
      userId: user.userId,
      threadName: capitalizeWords(topic) + " Quiz",
      courseId: selectedCourse,
      topic: topic,
      questionCount: questionCount,
      answerType: answerType,
    };
    try {
      const response = await fetch(`${process.env.REACT_APP_BACKEND_API}`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      });

      if (response.status === 200) {
        const data = await response.json();
        setUserThreads((currentThreads) => [data, ...currentThreads]);
        handleThreadClick(data, true);
      } else {
        alert(
          "There was an error creating a new conversation. Please submit feedback if this error persists."
        );
      }
    } catch (error) {
      console.error(
        "There was an error fetching the threadMessages data:",
        error
      );
    }
    return {};
  };

  const { createThread, isLoading: loadingCreateThread } = useCreateThread();
  const [createdNewThread, setCreatedNewThread] = useState(0);
  const handleNewThreadClick = async (firstMessage) => {
    try {
      const createThreadResponse = await createThread(
        user.userId,
        selectedCourse,
        firstMessage
      );
      if (createThreadResponse.data) {
        const thread = createThreadResponse.data;
        setUserThreads((currentThreads) => [thread, ...currentThreads]);
        setCreatedNewThread(createdNewThread + 1);
        await handleThreadClick(thread, true);
      } else {
        console.error(createThreadResponse);
        alert(
          "There was an error creating a new conversation. Please submit feedback if this error persists."
        );
      }
    } catch (error) {
      console.error(
        "There was an error fetching the threadMessages data:",
        error
      );
    }
    return {};
  };

  const { deleteThread } = useDeleteThread();
  const handleDeleteThread = async (thread) => {
    try {
      await deleteThread(user.userId, thread.threadId, selectedCourse);
      setUserThreads(userThreads.filter((t) => t.threadId !== thread.threadId));
      if (currentThread === thread) {
        setMessages([]);
        setCurrentThread({});
      }
    } catch (error) {
      alert(
        "There was an error deleting the thread. Please submit feedback if this error persists."
      );
    }
    return {};
  };

  const { renameThread } = useRenameThread();
  const handleRenameThread = async (thread, newName) => {
    try {
      const renameThreadResponse = await renameThread(thread.threadId, newName);
      if (renameThreadResponse) {
        setCurrentThread({ ...currentThread, threadName: newName });
        setUserThreads(
          userThreads.map((thread) =>
            thread.threadId === currentThread.threadId
              ? { ...thread, threadName: newName }
              : thread
          )
        );
      } else {
        console.error(renameThreadResponse);
        alert(
          "There was an error renaming the thread. Please submit feedback if this error persists."
        );
      }
    } catch (error) {
      console.error("There was an error updating the threadName:", error);
    }
    return {};
  };

  const [authenticating, setAuthenticating] = useState(false);

  const [isAuthenticated, setIsAuthenticated] = useState(!!user);

  const handleLoginFailure = (message) => {
    localStorage.removeItem("user");
    setSnackbarOpen(true);
    setSnackbarMessage(message);
    setIsAuthenticated(false);
  };

  const handleLoginSuccess = (user) => {
    localStorage.setItem("user", JSON.stringify(user));
    setIsAuthenticated(true);
  };

  const toggleAdmin = () => {
    setInAdmin(!!!inAdmin);
    toggleTheme();
    if (inAdmin) {
      handleCourseStats();
      fetchThreadsForCourse(selectedCourse, user.userId);
    } else {
      handleCourseStats();
    }
  };

  const handleCourseStats = () => {
    setSelectedStudent("courseStats");
    setCourseThreads([]);
    setMessages([]);
    setCurrentThread({});
    setUserThreads([]);
  };

  const handleLogout = () => {
    if (inAdmin) {
      toggleAdmin();
    }
    localStorage.removeItem("user");
    setIsAuthenticated(false);
    setMessages([]);
    setCurrentThread({});
    setSelectedCourse(null);
    setUserThreads([]);
    setUser(null);
    setCoursesData(null);
    setAnchorEl(null);
  };

  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const AUTH_FAILURE_MESSAGE =
    "Our authentication service is down, please try again later. Contact ahir@tailoredtutor.net if this persists.";
  const LOGGED_OUT_MESSAGE = "You have been logged out.";
  const TAILORED_TUTOR_DOWN_MESSAGE =
    "Tailored Tutor is currently unavailable for usage due to maitenance. Please contact ahir@tailoredtutor.net for more information.";

  const handleCloseSnackbar = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }
    setSnackbarOpen(false);
  };

  const { handleEnrollInCourse } = useHandleEnrollInCourse();
  const handleEnrollment = async (newCourseId) => {
    try {
      const handleEnrollResponse = await handleEnrollInCourse(
        user.userId,
        newCourseId
      );
      if (!handleEnrollResponse) {
        return false;
      } else {
        await setCoursesData((coursesData) => [...coursesData, newCourseId]);
        setSelectedCourse(newCourseId);
        return true;
      }
    } catch (error) {
      console.error("There was an error enrolling in the course:", error);
    }
  };

  if (!isAuthenticated) {
    return (
      <>
        <Snackbar open={snackbarOpen} onClose={handleCloseSnackbar}>
          <Alert onClose={handleCloseSnackbar} severity={"error"}>
            {snackbarMessage}
          </Alert>
        </Snackbar>
        <LoginPage
          onLoginSuccess={handleCredentialResponse}
          onPasswordLoginSuccess={handleSignupSuccess}
          authenticating={authenticating}
          theme={theme}
        />
      </>
    );
  }

  // if (isAnalytics) {
  //   return <p>Hello World!</p>
  // }

  if (isLoading) {
    return (
      <div
        style={{
          position: "fixed", // Fixed or absolute position
          top: 0,
          left: 0,
          width: "100%",
          height: "100%", // Full viewport height
          display: "flex",
          justifyContent: "center", // Center horizontally
          alignItems: "center", // Center vertically
          background: "rgba(255, 255, 255, 0.7)", // Optional: white translucent background
        }}
      >
        <CircularProgress />
      </div>
    );
  }

  return (
    <>
      {coursesData && (
        <>
          <SideBar
            loadingCreateThread={loadingCreateThread}
            setToGhostThread={setToGhostThread}
            isTyping={isTyping}
            createdNewThread={createdNewThread}
            setCourseThreads={setCourseThreads}
            drawerOpen={drawerOpen}
            drawerWidth={drawerWidth}
            selectedCourse={selectedCourse}
            handleCourseChange={handleCourseChange}
            threads={courseThreads}
            coursesData={inAdmin ? user.adminIn : coursesData}
            onThreadClick={handleThreadClick}
            onStartQuizClick={handleStartQuizClick}
            currentThread={currentThread}
            handleDeleteThread={handleDeleteThread}
            theme={theme}
            isAdmin={inAdmin}
            getStudentsInCourse={fetchStudentsForCourse}
            selectedStudent={selectedStudent}
            setSelectedStudent={setSelectedStudent}
            fetchThreadsForCourse={fetchThreadsForCourse}
            handleCourseStats={handleCourseStats}
            handleStudentChange={handleStudentChange}
            setCurrentThread={setCurrentThread}
            userThreads={userThreads}
            setUserThreads={setUserThreads}
            user={user}
            setMessages={setMessages}
            handleRenameThread={handleRenameThread}
          />
        </>
      )}

      <main style={mainStyle(drawerOpen)}>
        <NavBar
          drawerOpen={drawerOpen}
          setDrawerOpen={setDrawerOpen}
          anchorEl={anchorEl}
          handleMenu={handleMenu}
          handleClose={handleClose}
          open={open}
          handleLogout={handleLogout}
          toggleAdmin={toggleAdmin}
          isAdmin={user.adminIn ? user.adminIn.includes(selectedCourse) : false}
          inAdmin={inAdmin}
          handleEnroll={handleEnrollment}
          coursesData={inAdmin ? user.adminIn : coursesData}
          user={user}
          enrolledDepartments={user.enrolledDepartments}
          setCoursesData={setCoursesData}
          setSelectedCourse={setSelectedCourse}
          setCurrentThread={setCurrentThread}
          setMessages={setMessages}
        />
        {inAdmin && messages == null ? (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "flex-start",
              alignItems: "center",
              flexGrow: 1,
              height: "calc(100vh - 64px)", // This ensures the div will take up 100% of the viewport height
              overflowY: "scroll", // This forces the div to always show a scrollbar
              textAlign: "center",
              bgcolor: "background.default",
            }}
          >
            <CourseStatistics courseId={selectedCourse} />
          </div>
        ) : isLoadingThreadMessages ? (
          <ChatWindowSkeleton inAdmin={inAdmin} />
        ) : (
          <ChatWindow
            isLoadingThread={isLoadingThreads}
            drawerOpen={drawerOpen}
            loadingCreateThread={loadingCreateThread}
            setDrawerOpen={setDrawerOpen}
            messages={messages}
            sendMessage={sendMessage}
            currentMessage={currentMessage}
            setCurrentMessage={setCurrentMessage}
            setCurrentThread={setCurrentThread}
            thread={currentThreadRef}
            isTyping={isTyping}
            lastMessageSender={lastMessageSender}
            theme={theme}
            setMessages={setMessages}
            isAdmin={inAdmin}
            courseThreads={courseThreads}
            onNewThreadClick={handleNewThreadClick}
            onStartQuizClick={handleStartQuizClick}
            coursesData={coursesData}
            enrolledDepartments={user.enrolledDepartments}
            isLongAwait={isLongAwait}
          />
        )}

        <CookieConsent></CookieConsent>
      </main>
    </>
  );
};

export default App;
