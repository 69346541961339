import { createTheme } from "@mui/material/styles";

export const regularTheme = createTheme({
  palette: {
    primary: {
      main: "#bf5700",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#f8971f",
    },
    background: {
      default: "#F4F7FA",
      paper: "#FFFFFF",
    },
    error: {
      main: "#FF6347",
    },
    text: {
      primary: "#212121",
      secondary: "#5F6368",
    },
    action: {
      active: "#00B4AB",
    },
    link: {
      unclicked: "#5C6BC0"
    }
  },
  components: {
    MuiMenu: {
      styleOverrides: {
        paper: {
          boxShadow: "0px 4px 6px rgba(0, 0, 0, 0.1)",
        },
      },
    },
  },
});

export const adminTheme = createTheme({
  palette: {
    primary: {
      main: "#5C6BC0",
      contrastText: "#FFFFFF",
    },
    secondary: {
      main: "#FF8A65",
    },
    background: {
      default: "#37474F",
      paper: "#455A64",
    },
    error: {
      main: "#E57373",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#BDBDBD",
    },
    action: {
      active: "#FFD54F",
    },
  },
  // Additional admin theme overrides...
  // For example, customizing the AppBar, Button styles, etc.
});
